import * as React from "react"
import { Link, HeadFC } from "gatsby"

import Layout from "../components/layout"
import PageHead from "../components/page-head"

import "../styles/globals.scss"
import "../styles/error.scss"

const NotFoundPage = () => {
  return (
    <Layout className="error">
      <div className="section hero">
        <h3>Page not found</h3>
        <p>
          Sorry, we couldn't find what you were looking for.
        </p>
      </div>
    </Layout>    
  )
}

export default NotFoundPage

export const Head: HeadFC = () => <PageHead />
