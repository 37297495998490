import * as React from "react"
import Seo from "../components/seo"

type PageHeadProps = {
  title?: string
}

function PageHead({ title }: PageHeadProps) {

  return (
    <>
      <Seo title={title} />
    </>
  )
}

export default PageHead
