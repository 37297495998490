import * as React from "react"

import ogBanner from "../images/og-image.png"

type SeoProps = {
  title?: string
  description?: string
  children?: React.ReactNode
}

function Seo({ description, title, children }: SeoProps) {

  const metaDescription = description || 'We make the hypothetical concrete and the complex tangible.'
  const pageTitle = title || 'Align'
  return (
    <>
      <title>{pageTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta property='og:image' content={ogBanner} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <link rel="canonical" href="http://align.fi" />
      <script defer data-domain="align.fi" src="https://plausible.io/js/plausible.js"></script>
      {children}
    </>
  )
}

export default Seo
