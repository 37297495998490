import * as React from "react"

import Header from "./header"

type LayoutProps = {
  className: string
  children: React.ReactNode
}

const Layout = ({ className, children }: LayoutProps) => {
  return (
    <div className={className}>
      <Header />
      {children}      
    </div>
  )
}

export default Layout
