import * as React from "react"
import classnames from "classnames"
import { useLocation } from '@reach/router'
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import "./header.scss"

const Header = () => { 
  const location = useLocation()
  return (
    <header className="header">
      <div className="navigation">
        <Link
          to="/"
          className={classnames({ 'current': location.pathname === '/' })}
        >
          About Align
        </Link>
        <Link
          to="/expertise"
          className={classnames({ 'current': location.pathname !== '/' })}
        >
          Our Expertise
        </Link>
      </div>
    </header>
  )
}

export default Header
